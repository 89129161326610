import React from 'react'
import Helmet from 'react-helmet'
import { graphql } from 'gatsby'

import { FeaturedPost, FeaturedQuote, GridItemPost, Layout } from '../components'

const BlogIndex = ({ data, location }) => {
  const { title, description, siteUrl } = data.site.siteMetadata
  const { tagline, featuredSlug } = data.currentCategory.edges[0].node
  const { edges } = data.allMarkdownRemark
  const featuredHomePost = edges.filter(i => i.node.fields.slug === featuredSlug)[0].node

  return (
    <Layout location={ location }>
      <Helmet
        htmlAttributes={ { lang: 'en' } }
        meta={ [
          { name: 'description', content: description },
          { property: 'og:locale', content: 'en_US' },
          { property: 'og:type', content: 'blog' },
          { property: 'og:title', content: title },
          { property: 'og:description', content: description },
          { property: 'og:url', content: siteUrl },
          { property: 'og:site_name', content: title },
          { property: 'article:publisher', content: 'https://signalsciences.com' },
          { property: 'article:section', content: 'Uncategorized' },
          { property: 'og:image', content: `${siteUrl}${featuredHomePost.frontmatter.featuredImage.childImageSharp.sizes.src}` },
          { property: 'twitter:card', content: 'summary_large_image' },
          { property: 'twitter:description', content: description },
          { property: 'twitter:title', content: title },
          { property: 'twitter:site', content: '@signalsciences' },
          { property: 'twitter:image', content: `${siteUrl}${featuredHomePost.frontmatter.featuredImage.childImageSharp.sizes.src}` },
          { property: 'twitter:creator', content: '@signalsciences' },
        ] }
        link={ [{ rel: 'canonical', href: siteUrl }] }
        title={ title }
      />
      <div className='PageContainer'>
        <FeaturedQuote quote={ tagline } />
        { featuredHomePost && <FeaturedPost post={ featuredHomePost } /> }
        <p className='GridHeader'>Recent Articles</p>
        <section className='Grid'>
          { edges.map(({ node }) => node.fields.slug !== featuredSlug && (
            <GridItemPost
              key={ node.fields.slug }
              post={ node }
              defaultImage={ data.defaultImage }
            />
          )) }
        </section>
      </div>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
        siteUrl
      }
    }
    currentCategory: allCategoryYaml(filter: { id: { eq: "home" } }) {
      edges {
        node {
          tagline
          featuredSlug
        }
      }
    }
    defaultImage: file(relativePath: { eq: "default.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 760) {
          ...GatsbyImageSharpSizes
        }
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            date(formatString: "MMMM D, YYYY")
            title
            author {
              id
              bio
              url
            }
            category
            excerpt
            featuredImage {
              childImageSharp {
                sizes(maxWidth: 760) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
            featuredImageAltText
          }
        }
      }
    }
  }
`
